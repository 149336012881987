import {
    ACQUISITION_DATETIME_TRAIT_NAME,
    PROCESSING_PROJECT_VERSION,
} from "../../../constants";

export function regroupByPhenostation(entities, sortByDatetime = false) {
    const phenostationList = [];

    entities.forEach((entity) => {
        const phenostation = phenostationList.find(
            (pheno) => pheno.uuid === entity.Acquisition.Phenostation.uuid
        );
        if (phenostation) {
            // The phenostation is already in the list
            phenostation.entities.push(entity);
        } else {
            // The phenostation is not yet in the list
            phenostationList.push({
                ...entity.Acquisition.Phenostation,
                entities: [entity],
            });
        }
    });

    phenostationList.sort(nameSort);

    if (sortByDatetime) {
        phenostationList.forEach((phenostation) =>
            phenostation.entities.sort(
                (a, b) =>
                    a.Acquisition.acquisitionDatetime -
                    b.Acquisition.acquisitionDatetime
            )
        );
    }

    return phenostationList;
}

/**
 * The reference used by this function includes the entity's phenostation name in addition to
 * its original entityRef in order to differentiate entities which have the same entityRef but
 * from different phenostations.
 */
export function regroupByStationEntityRef(entities) {
    const groupList = [];

    entities.forEach((entity) => {
        const group = groupList.find(
            (groupObject) => groupObject.name === getStationEntityRef(entity)
        );
        if (group) {
            // The group is already in the list
            group.entities.push(entity);
        } else {
            // The group is not yet in the list
            groupList.push({
                name: getStationEntityRef(entity),
                entities: [entity],
            });
        }
    });

    groupList.sort(nameSort);

    groupList.forEach((group) =>
        group.entities.sort(
            (a, b) =>
                a.Acquisition.acquisitionDatetime -
                b.Acquisition.acquisitionDatetime
        )
    );

    return groupList;
}

export function regroupByProcessingVersion(entities) {
    const processingVersionList = [];

    entities.forEach((entity) => {
        const processingVersion = processingVersionList.find(
            (processingVer) =>
                processingVer.name === entity.processingProjectVersion
        );
        if (processingVersion) {
            // The processingVersion is already in the list
            processingVersion.entities.push(entity);
        } else {
            // The processingVersion is not yet in the list
            processingVersionList.push({
                name: entity.processingProjectVersion,
                entities: [entity],
            });
        }
    });

    processingVersionList.sort(nameSort);

    return processingVersionList;
}

export function regroupByTagValue(entities, tagKey) {
    const groupList = [];

    entities.forEach((entity) => {
        let tagValue = entity.tags
            .find((tag) => tag.split("=")[0] === tagKey)
            ?.split("=")[1];

        tagValue = tagValue ? tagValue : "No value";
        const group = groupList.find(
            (groupObject) => groupObject.name === tagValue
        );
        if (group) {
            // The group is already in the list
            group.entities.push(entity);
        } else {
            // The group is not yet in the list
            groupList.push({
                name: tagValue,
                entities: [entity],
            });
        }
    });

    return groupList.sort(nameSort);
}

export function getEntityTrait(entity, traitName) {
    if (traitName === ACQUISITION_DATETIME_TRAIT_NAME)
        return entity.Acquisition.acquisitionDatetime;
    else if (traitName === PROCESSING_PROJECT_VERSION)
        return entity.processingProjectVersion;
    else return entity.traits[traitName]?.value;
}

function nameSort(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

function getStationEntityRef(entity) {
    return `${entity.Acquisition.Phenostation.name} ${entity.entityRef}`;
}
