import { CHART_HEIGHT, PLOT_TABS } from "../../../constants";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Box } from "@mui/system";
import { Equalizer } from "@mui/icons-material";
import { Histogram } from "../graphs/Histogram";
import { ScatterPlot } from "../graphs/ScatterPlot";
import { Section } from "../../../components/Section";
import { Tab } from "@mui/material";

export const PlotSection = ({
    entities,
    groupMode,
    xTrait,
    yTrait,
    setEvaluationEntities,
    binCount,
    activeTab,
    setActiveTab,
    markerOpacity,
    lineWidth,
    showLegend,
    uirevision,
}) => {
    const handleChange = (_, newValue) => {
        setActiveTab(newValue);
    };

    const onSelected = (selectedEntities) => {
        // In case of an "unselection", selectedEntities is undefined,
        // in which case we do not update evaluation entities.
        // selectedEntities can be an empty array because of an empty selection (and because of a plotly.js bug), in which case we do nothing.
        if (selectedEntities?.length) setEvaluationEntities(selectedEntities);
    };

    return (
        <Section
            expandable
            icon={<Equalizer />}
            height={CHART_HEIGHT}
            title="Trait Graphs"
            popoverMessage="The graph only displays entites whose selected traits' value is not null. "
        >
            <TabContext value={activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange}>
                        <Tab label="Histogram" value={PLOT_TABS.HISTOGRAM} />
                        <Tab
                            label="Scatter Plot"
                            value={PLOT_TABS.SCATTER_PLOT}
                        />
                    </TabList>
                </Box>
                <TabPanel value={PLOT_TABS.HISTOGRAM} sx={{ height: 1 }}>
                    {xTrait && entities.length > 0 ? (
                        <Histogram
                            entities={entities}
                            groupMode={groupMode}
                            traitName={xTrait}
                            onSelected={onSelected}
                            binCount={binCount}
                            showLegend={showLegend}
                            uirevision={uirevision}
                        />
                    ) : (
                        "Get entities to show the histogram"
                    )}
                </TabPanel>
                <TabPanel value={PLOT_TABS.SCATTER_PLOT} sx={{ height: 1 }}>
                    {xTrait && yTrait ? (
                        <ScatterPlot
                            entities={entities}
                            groupMode={groupMode}
                            traitNameX={xTrait}
                            traitNameY={yTrait}
                            onSelected={onSelected}
                            markerOpacity={markerOpacity}
                            lineWidth={lineWidth}
                            showLegend={showLegend}
                            uirevision={uirevision}
                        />
                    ) : (
                        "Select the X trait and the Y trait to show the scatter plot"
                    )}
                </TabPanel>
            </TabContext>
        </Section>
    );
};
