import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

Logo.propTypes = {
    sx: PropTypes.object,
};

export default function Logo({ sx }) {
    return (
        <RouterLink to="/">
            <Box
                component="img"
                src="/static/logo.svg"
                sx={{
                    width: 40,
                    height: 40,
                    ...sx,
                }}
            />
        </RouterLink>
    );
}
