import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import { APPBAR_HEIGHT_MOBILE } from "./constants";
import { APP_NAME } from "../../constants";
import AccountPopover from "./AccountPopover";
import Logo from "../../components/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(() => ({
    minHeight: APPBAR_HEIGHT_MOBILE,
}));

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    return (
        <RootStyle>
            <ToolbarStyle>
                <IconButton
                    onClick={onOpenSidebar}
                    sx={{
                        mr: 1,
                        color: "text.primary",
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Logo />
                <Typography color="primary" fontWeight="bold" sx={{ ml: 1 }}>
                    {APP_NAME}
                </Typography>

                <Box sx={{ flexGrow: 1 }} />

                <AccountPopover />
            </ToolbarStyle>
        </RootStyle>
    );
}
