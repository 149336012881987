import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { MODEL_PROPTYPES, PAGINATION } from "../../../constants";
import { useEffect, useMemo, useState } from "react";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { EntityFileVisualizer } from "./EntityFileVisualizer";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/formatTime";
import { useSWRAllPages } from "../../../hooks/useSWRAllPages";

EntityVisualizer.propTypes = {
    entity: PropTypes.shape({
        ...MODEL_PROPTYPES.EntityWithParents,
    }),
    xTrait: PropTypes.string,
    yTrait: PropTypes.string,
};

const SELECT_LABEL_HELPER_TEXT = "Select a label to show the visualisation.";

export function EntityVisualizer({ entity, xTrait, yTrait }) {
    const { data: entityFiles, error: entityFileFetchError } = useSWRAllPages(
        entity
            ? `${BACKEND_ROUTES.ENTITY}/${entity.uuid}/${BACKEND_ROUTES.ENTITY_ENTITY_FILES}`
            : null,
        PAGINATION.GENERIC.LIMIT.MAX
    );

    const labels = useMemo(
        () =>
            entityFiles?.map((entityFile) => entityFile.label).toSorted() ?? [],
        [entityFiles]
    );

    const [label, setLabel] = useState(null);
    const entityFile = entityFiles?.find(
        (entityFile) => entityFile.label === label
    );

    // Select first label by default
    useEffect(() => {
        if (!label && entityFiles?.length > 0) {
            setLabel(entityFiles[0].label);
        }
    }, [entityFiles, label]);

    const mergedError = entityFileFetchError;

    if (mergedError) return <FetchErrorAlert error={mergedError} />;

    return entity ? (
        <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid item xs={8}>
                <Typography variant="caption">
                    {`${entity.Acquisition.Phenostation.name} ${formatDateTime(
                        entity.Acquisition.acquisitionDatetime
                    )} ${entity.entityRef}`}
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Autocomplete
                    fullWidth
                    size="small"
                    options={labels}
                    value={label}
                    onChange={(_, value) => setLabel(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Label"
                            placeholder="Select a label"
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                {entityFile ? (
                    <EntityFileVisualizer
                        entityFile={entityFile}
                        entity={entity}
                        xTrait={xTrait}
                        yTrait={yTrait}
                        /**
                         * By passing label as a key to the EntityFileVisualizer component,
                         * we’re asking React to treat two EntityFileVisualizer components with different labels
                         * as two different components that should not share any state.
                         * https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
                         * (In the DB, label should be non-nullable and unique among the EntityFiles of an Entity)
                         */
                        key={entityFile.label}
                    />
                ) : (
                    <Typography>{SELECT_LABEL_HELPER_TEXT}</Typography>
                )}
            </Grid>
        </Grid>
    ) : null;
}
