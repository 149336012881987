import {
    CHART_MARGINS,
    CHART_MODEBAR_BUTTONS_TO_REMOVE,
    GROUP_MODES,
    MODEL_PROPTYPES,
} from "../../../constants";
import {
    getEntityTrait,
    regroupByPhenostation,
    regroupByProcessingVersion,
    regroupByStationEntityRef,
    regroupByTagValue,
} from "../utils/dataStructure";

import Plot from "react-plotly.js";
import PropTypes from "prop-types";

ScatterPlot.propTypes = {
    entities: PropTypes.arrayOf(
        PropTypes.shape({
            ...MODEL_PROPTYPES.EntityWithParents,
        })
    ).isRequired,
    groupMode: PropTypes.string.isRequired,
    traitNameX: PropTypes.string.isRequired,
    traitNameY: PropTypes.string.isRequired,
    onSelected: PropTypes.func,
    uirevision: PropTypes.any,
    markerOpacity: PropTypes.number,
    lineWidth: PropTypes.number,
    showLegend: PropTypes.bool,
};

/**
 * onSelected: Callback fired when some points are selected or there is an "unselection".
 * Signature: function(selectedEntities: Array|undefined) => void
 * selectedEntities: An array of selected entities or undefined if there is an "unselection".
 */
export function ScatterPlot({
    entities,
    groupMode,
    traitNameX,
    traitNameY,
    onSelected,
    uirevision,
    markerOpacity,
    lineWidth,
    showLegend,
}) {
    let groupList;
    let traceMode;

    groupList = entities.filter(
        (entity) =>
            getEntityTrait(entity, traitNameX) != null &&
            getEntityTrait(entity, traitNameY) != null
    );

    switch (groupMode) {
        case GROUP_MODES.PHENOSTATION:
            groupList = regroupByPhenostation(groupList);
            traceMode = "markers";
            break;
        case GROUP_MODES.ENTITY:
            groupList = regroupByStationEntityRef(groupList);
            traceMode = "lines+markers";
            break;
        case GROUP_MODES.PROCESSING_VERSION:
            groupList = regroupByProcessingVersion(groupList);
            traceMode = "markers";
            break;
        default:
            // If the selected option is not a "group mode", the groupMode is a "tag"
            groupList = regroupByTagValue(groupList, groupMode);
            traceMode = "markers";
    }

    return (
        <Plot
            data={groupList.map((group) => {
                return {
                    x: group.entities.map((entity) =>
                        getEntityTrait(entity, traitNameX)
                    ),
                    y: group.entities.map((entity) =>
                        getEntityTrait(entity, traitNameY)
                    ),
                    type: "scattergl",
                    mode: traceMode,
                    marker: {
                        opacity: markerOpacity,
                    },
                    line: { width: 2.0 * lineWidth },
                    name: group.name,
                };
            })}
            layout={{
                xaxis: { title: traitNameX },
                yaxis: { title: traitNameY },
                margin: CHART_MARGINS,
                dragmode: "select",
                autosize: true,
                uirevision: uirevision,
                showlegend: showLegend,
            }}
            onSelected={(eventData) => {
                if (onSelected) {
                    // eventData is undefined in the case of an "unselection" (a double-click for example)
                    if (eventData !== undefined) {
                        const selectedEntities = [];

                        eventData.points.forEach((point) => {
                            selectedEntities.push(
                                groupList[point.curveNumber].entities[
                                    point.pointNumber
                                ]
                            );
                        });

                        onSelected(selectedEntities);
                    } else onSelected(undefined);
                }
            }}
            useResizeHandler
            style={{ width: "100%", height: "100%" }}
            config={{
                displaylogo: false,
                modeBarButtonsToRemove: CHART_MODEBAR_BUTTONS_TO_REMOVE,
            }}
        />
    );
}
