import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    IconButton,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import {
    ENTITY_FETCH_WARNING_THRESHOLD,
    INVALID_DATE_HELPER_TEXT,
} from "../../../constants";

import { AcquisitionTimeHistogramSliderDialog } from "./AcquisitionTimeHistogramSliderDialog";
import { ArrowForward } from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { ConfirmModal } from "../../../components/ConfirmModal";
import { DateTimePicker } from "@mui/x-date-pickers";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Section } from "../../../components/Section";
import WarningIcon from "@mui/icons-material/Warning";
import { useState } from "react";

const ADD_ALL_PHENOSTATIONS_OPTION = {
    uuid: "fake add-all phenostations option uuid",
    name: "Select all",
};

export const DataSourceSection = ({
    project,
    phenostations,
    selectedPhenostations,
    setSelectedPhenostations,
    startDatetime,
    setStartDatetime,
    endDatetime,
    setEndDatetime,
    entitiesFetchIsValidating,
    entitiesCount,
    save,
}) => {
    // The confirm modal is used as a warning when the user tries to fetch a very large number of entities
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    const [isOpenAcquisitionTimeHistogram, setIsOpenAcquisitionTimeHistogram] =
        useState(false);

    const [phenostationsMenuIsOpen, setPhenostationsMenuIsOpen] =
        useState(false);

    return (
        <Section icon={<AutoAwesomeMotionIcon />} title="Data source">
            <Stack spacing={1} sx={{ mt: 1 }}>
                <Autocomplete
                    sx={{ flexGrow: 1 }}
                    open={
                        phenostationsMenuIsOpen ||
                        selectedPhenostations.length < 1
                    }
                    onOpen={() => setPhenostationsMenuIsOpen(true)}
                    onClose={() => setPhenostationsMenuIsOpen(false)}
                    multiple
                    size="small"
                    filterSelectedOptions
                    disableCloseOnSelect
                    value={selectedPhenostations}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value, reason, details) => {
                        if (
                            reason === "selectOption" &&
                            details.option.uuid ===
                                ADD_ALL_PHENOSTATIONS_OPTION.uuid
                        )
                            setSelectedPhenostations(phenostations);
                        else setSelectedPhenostations(value);
                    }}
                    options={
                        // Add an "Select all" button only if not all phenostations are selected
                        phenostations.length === selectedPhenostations.length
                            ? phenostations
                            : phenostations.concat([
                                  ADD_ALL_PHENOSTATIONS_OPTION,
                              ])
                    }
                    isOptionEqualToValue={(option, value) => {
                        return option.uuid === value.uuid;
                    }}
                    renderInput={(params) => (
                        <TextField
                            autoFocus
                            {...params}
                            label="Phenostations"
                            placeholder="Select phenostations"
                        />
                    )}
                />

                <Stack direction="row">
                    <DateTimePicker
                        label="Start datetime"
                        value={startDatetime}
                        onChange={(newValue) => setStartDatetime(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                helperText={
                                    params.error && INVALID_DATE_HELPER_TEXT
                                }
                            />
                        )}
                        ampm={false}
                        inputFormat="yyyy-MM-dd HH:mm"
                        mask={"____-__-__ __:__"}
                    />

                    <Stack direction="row" flexGrow={1} justifyContent="center">
                        <Tooltip title="Acquisition time histogram slider">
                            {/* The span is necessary for Tooltip to work when IconButton is disabled */}
                            <span>
                                <IconButton
                                    onClick={() =>
                                        setIsOpenAcquisitionTimeHistogram(true)
                                    }
                                    disabled={!selectedPhenostations.length}
                                >
                                    <AssessmentIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Stack>

                <Stack direction="row">
                    <DateTimePicker
                        label="End datetime"
                        value={endDatetime}
                        onChange={(newValue) => setEndDatetime(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                helperText={
                                    params.error && INVALID_DATE_HELPER_TEXT
                                }
                            />
                        )}
                        ampm={false}
                        inputFormat="yyyy-MM-dd HH:mm"
                        mask={"____-__-__ __:__"}
                    />

                    <Stack direction="row" flexGrow={1} justifyContent="center">
                        <Tooltip title="Set to now">
                            <IconButton
                                onClick={() => {
                                    setEndDatetime(new Date());
                                }}
                                variant="outlined"
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>

                <Button
                    startIcon={
                        entitiesFetchIsValidating ? (
                            <CircularProgress size="1em" />
                        ) : (
                            <ArrowForward />
                        )
                    }
                    onClick={() => {
                        // If the amount of entities to fetch is very large, show a warning dialog for confirmation.
                        // Otherwise, fetch directly.
                        if (entitiesCount > ENTITY_FETCH_WARNING_THRESHOLD)
                            setIsOpenConfirmModal(true);
                        else save();
                    }}
                    variant="contained"
                    disabled={entitiesFetchIsValidating || entitiesCount === 0}
                    endIcon={
                        entitiesCount > ENTITY_FETCH_WARNING_THRESHOLD && (
                            <Tooltip title="The amount of entities to fetch is very large.">
                                <WarningIcon />
                            </Tooltip>
                        )
                    }
                >
                    Get {entitiesCount}{" "}
                    {entitiesCount > 1 ? "Entities" : "Entity"}{" "}
                </Button>
            </Stack>

            <Dialog
                open={isOpenAcquisitionTimeHistogram}
                fullWidth
                maxWidth="xl"
            >
                <AcquisitionTimeHistogramSliderDialog
                    project={project}
                    selectedPhenostations={selectedPhenostations}
                    onCancel={() => setIsOpenAcquisitionTimeHistogram(false)}
                    onConfirm={(startDatetime, endDatetime) => {
                        setIsOpenAcquisitionTimeHistogram(false);
                        setStartDatetime(startDatetime);
                        setEndDatetime(endDatetime);
                    }}
                    initialSliderStart={startDatetime}
                    initialSliderEnd={endDatetime}
                />
            </Dialog>

            <ConfirmModal
                isOpen={isOpenConfirmModal}
                onCancel={() => setIsOpenConfirmModal(false)}
                onConfirm={() => {
                    setIsOpenConfirmModal(false);
                    save();
                }}
                title="Performance warning"
                contentText="The amount of entities you are about to fetch is very large, which can negatively affect performance. 
                    Are you sure you want to continue?"
            />
        </Section>
    );
};
