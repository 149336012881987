import {
    ACQUISITION_DATETIME_TRAIT_NAME,
    GROUP_MODES,
    MAX_BIN_COUNT,
    MIN_BIN_COUNT,
    PLOT_TABS,
    PROCESSING_PROJECT_VERSION,
} from "../../../constants";
import {
    Autocomplete,
    Box,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    TextField,
} from "@mui/material";

import { InfoPopover } from "../../../components/InfoPopover";
import { Label } from "@mui/icons-material";
import { Section } from "../../../components/Section";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

const optionGroupBy = (option) => {
    switch (option) {
        case ACQUISITION_DATETIME_TRAIT_NAME:
            return "Datetime";
        case PROCESSING_PROJECT_VERSION:
            return "Processing Info";
        default:
            return "Traits";
    }
};

export const GraphControlsSection = ({
    xTraitList,
    yTraitList,
    xTrait,
    setXTrait,
    yTrait,
    setYTrait,
    groupMode,
    setGroupMode,
    binCount,
    setBinCount,
    activeTab,
    tagKeys,
}) => {
    return (
        <Section icon={<StackedBarChartIcon />} title="Graph controls">
            <Stack spacing={1} sx={{ mt: 1 }}>
                <Autocomplete
                    size="small"
                    options={xTraitList}
                    value={xTrait}
                    onChange={(_, value) => setXTrait(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="X trait"
                            placeholder="Select X Trait"
                        />
                    )}
                    groupBy={optionGroupBy}
                />

                <Autocomplete
                    size="small"
                    options={yTraitList}
                    value={yTrait}
                    onChange={(_, value) => setYTrait(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Y trait"
                            placeholder="Select Y Trait"
                        />
                    )}
                    groupBy={optionGroupBy}
                />

                <FormControl fullWidth>
                    <InputLabel id="group-mode-select-label">
                        Group Mode
                    </InputLabel>
                    <Select
                        size="small"
                        labelId="group-mode-select-label"
                        id="group-mode-select"
                        value={groupMode}
                        label="Group Mode"
                        onChange={(event) => {
                            setGroupMode(event.target.value);
                        }}
                    >
                        {Object.values(GROUP_MODES).map((groupModeString) => (
                            <MenuItem
                                value={groupModeString}
                                key={groupModeString}
                            >
                                {groupModeString}
                            </MenuItem>
                        ))}
                        {tagKeys.map((tagKey) => (
                            <MenuItem value={tagKey} key={tagKey}>
                                <Label fontSize="small" sx={{ mr: 0.5 }} />
                                {tagKey}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ px: 2 }}>
                    <FormLabel>
                        Bin count: {binCount}{" "}
                        <InfoPopover msg="The actual bin count might be different in the calculated histogram." />
                    </FormLabel>
                    <Slider
                        disabled={activeTab === PLOT_TABS.SCATTER_PLOT}
                        value={binCount}
                        onChange={(_, value) => {
                            setBinCount(value);
                        }}
                        min={MIN_BIN_COUNT}
                        max={MAX_BIN_COUNT}
                    />
                </Box>
            </Stack>
        </Section>
    );
};
